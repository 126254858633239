.dashboard-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  color: #4b4b4b;
  padding: 0.5vw;
  height: 100%;
  border: 2px solid #4b4b4b;
  border-radius: 10px;
  margin-top: 1vw;
  margin-right: 1vw;
}

.dashboard-list > .header {
  font-size: 1vw;
  margin-bottom: 0.1vw;
  color: var(--tertiary-color);
}

.dashboard-list > .search-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 7px 0;
}

.dashboard-list > .list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 130px);
  overflow-y: auto;
  margin-top: 0.5vw;
}

.dashboard-list > .list-container > .list-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1vw;
  width: 100%;
}

.dashboard-list > .list-container > .list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 92%;
  border: 2px solid #4b4b4b;
  border-radius: 10px;
  padding: 2.5%;
  margin-bottom: 0.5vw;
  background: #ffffff;
}

.dashboard-list .list-item > .buttons {
  display: flex;
  flex-direction: row;
}

.dashboard-list .list-item > .buttons > .action-btn {
  cursor: pointer;
  margin-left: 0.1rem;
  width: 1.1rem;
}

.dashboard-list > .list-item > .buttons > .action-btn.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.dashboard-list .list-item > .name {
  font-size: 1.1rem;
  font-weight: 300;
  text-transform: capitalize;
}

.dashboard-list > .list-container > .list-item .list-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.dashboard-list > .list-container > .list-item .list-item.selected > .name {
  font-weight: bold;
}

.dashboard-list > .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.5vw;
}

.dashboard-list > .buttons > .add-button {
  cursor: pointer;
}

.list-item > .list-item {
  /* width: 90% !important; */
}

.list-item > .image > img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 10px;
}

.sub-items > .list-item {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  /* margin-left: 0.3vw; */
  /* border-left: 1px solid #4b4b4b; */
}

.sub-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 0.5vw);
  margin-left: 0.5vw;
}

.sub-items.first-level {
  margin-left: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .dashboard-list {
    width: 100%;
    margin-right: 0;
    border: none;
    height: calc(100% - 10px);
    margin-top: 0;
  }

  .dashboard-list > .header > .title {
    display: none;
  }

  .dashboard-list .list-item > .name {
    font-size: 1rem;
  }

  .dashboard-list .list-item > .buttons > .action-btn {
    width: 1.2rem;
    margin-left: 0.2rem;
  }

  .sub-items {
    width: calc(100% - 1rem);
    margin-left: 1rem;
  }

  .dashboard-list > .list-container > .list-item {
    margin-bottom: 5px;
  }

  .dashboard-list > .list-container {
    height: calc(100% - 180px);
  }
}
