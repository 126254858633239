.dark-theme {
  --primary-color: #4b4b4b;
  --secondary-color: #1e1e1e;
  --tertiary-color: #ffffff;
  --quaternary-color: #000000;
  --quinary-color: #7d6900;
}

.light-theme {
  --primary-color: #4b4b4b;
  --secondary-color: #ffffff;
  --tertiary-color: #1e1e1e;
  --quaternary-color: #000000;
  --quinary-color: #7d6900;
}

.container {
  background-color: var(--secondary-color);
  color: var(--tertiary-color);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--quinary-color) transparent;
  scroll-behavior: smooth;
}

.logo {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
