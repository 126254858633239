.drop-down-wrapper {
  width: 100%;
  margin-bottom: 20px;
}

/* Input style */

/* Label */
.drop-down-wrapper label {
  font-weight: 600;
  font-size: 18px;
  color: var(--tertiary-color);
  display: block;
  margin-bottom: 8px;
}

.drop-down-wrapper select {
  font-size: 16px;
  font-weight: 400;
  color: var(--tertiary-color);
  background: var(--secondary-color);
  width: 100%;
  border: none;
  outline: none;
}

.drop-down-wrapper .drop-down {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 14px;
  width: calc(100% - 28px);
}

.drop-down-wrapper .drop-down.focused {
  box-shadow: 0 0 10px 5px rgba(125, 104, 0, 0.32);
}

.drop-down-wrapper .error {
  color: #db4437;
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
  margin-top: 4px;
}
