.text-area-wrapper {
  width: 100%;
  margin-bottom: 20px;
}

/* Input style */

/* Label */
.text-area-wrapper label {
  font-weight: 600;
  font-size: 18px;
  color: var(--tertiary-color);
  display: block;
  margin-bottom: 8px;
}

/* Input field */
.text-area-wrapper textarea {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-color);
  background: var(--tertiary-color);
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 14px;
  resize: none;
  width: calc(100% - 28px);
  height: 100px;
}

.text-area-wrapper textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #a0a7b8;
}

/* Input field focus */
.text-area-wrapper textarea:focus {
  outline: none;
  box-shadow: 0 0 10px 5px rgba(125, 104, 0, 0.32);
}

.text-area-wrapper .error {
  color: #db4437;
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
  margin-top: 4px;
}
