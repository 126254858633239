.items-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
}

.items-section-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--tertiary-color);
}

.full-width {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .items-section {
    padding: 0 0.5rem;
    width: calc(100% - 1rem);
    gap: 0.5rem;
  }

  .items-section-title {
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }
}
