.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #4b4b4b;
  width: 100%;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.page-header > .menu-btns {
  display: none;
  flex-direction: row;
  align-items: center;
}

.page-header > .menu-btns > .menu-btn {
  display: block;
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 10px;
  padding: unset;
}

.page-header > .menu-btns > .menu-btn img {
  width: 100%;
  height: 100%;
}

.menu {
  display: none;
  z-index: 100;
  position: absolute;
  background: var(--secondary-color);
  height: calc(100vh - 112px);
  width: 100%;
  transform: translateY(-100%);
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.menu > .menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  overflow-y: auto;
  border-bottom: 2px solid #4b4b4b;
}

.menu > .menu-item > .icon {
  padding: 10px;
  width: 42px;
}

.menu > .menu-item > .name {
  color: var(--tertiary-color);
  font-size: 26px;
  margin-left: 10px;
}

.page-header > .indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.page-header > .indicator .logo {
  padding: 0.5vw;
  width: 5vw;
}

.page-header > .indicator > .page-title {
  color: var(--tertiary-color);
  font-size: 1vw;
}

@media (max-width: 767px) {
  .menu.open {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    animation: slideDown 0.3s forwards;
  }

  .page-header {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .page-header > .menu-btns {
    display: flex;
  }

  .page-header > .menu-btns > .menu-btn {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 10px;
    padding: unset;
  }

  .page-header > .indicator .logo {
    width: 70px;
    padding: 5px;
  }

  .page-header > .indicator > .page-title {
    font-size: 1.3rem;
    margin-left: 5px;
  }
}
