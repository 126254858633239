.input-wrapper {
  width: 100%;
  margin-bottom: 20px;
}

/* Input style */

/* Label */
.input-wrapper label {
  font-weight: 600;
  font-size: 18px;
  color: var(--tertiary-color);
  display: block;
  margin-bottom: 8px;
}

.input-wrapper .input {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px 14px;
  width: calc(100% - 28px);
  background-color: var(--tertiary-color);
}

/* Input field */
.input-wrapper input {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-color);
  background: var(--tertiary-color);
  width: 100%;
  border: none;
  outline: none;
}

.input-wrapper input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #a0a7b8;
}

/* Input field focus */
.input-wrapper .input > input:focus {
  border: none;
  outline: none;
}

.input-wrapper .input.focused {
  box-shadow: 0 0 10px 5px rgba(125, 104, 0, 0.32);
}

.input-wrapper .error {
  color: #db4437;
  font-size: 14px;
  font-weight: 400;
  margin-left: 12px;
  margin-top: 4px;
}

.input-wrapper img {
  height: 150px;
  width: auto;
  margin: 0 auto;
}
