.product-page {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 72px);
  padding: 30px;
  background-color: var(--tertiary-color);
  border-radius: 5px;
  border: 6px solid var(--quinary-color);
  color: var(--quinary-color);
}

.product-page > .images {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 40%;
  height: 100%;
}

.product-page > .images > .current-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: calc(100% - 10px);
  background-color: var(--tertiary-color);
  border-radius: 5px;
  border: 5px solid var(--quinary-color);
}

.product-page > .images > .current-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1;
  aspect-ratio: 1 / 1;
}

.product-page > .images > .thumbnails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 20px);
  padding: 10px;
}

.product-page > .images > .thumbnails > .images {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.product-page > .images > .thumbnails > .images > img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
  border: 2px solid var(--quinary-color);
  background-color: var(--tertiary-color);
  aspect-ratio: 1/1;
}

.product-page > .images > .thumbnails > .images > img.selected {
  border: 3px solid var(--quinary-color);
}

.product-page > .images > .thumbnails > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  background-color: unset;
  border: unset;
  cursor: pointer;
  outline: none;
}

.product-page > .data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  height: 100%;
  padding: 10px;
}

.product-page > .data > .brand {
  font-size: 1.5em;
  font-weight: 300;
  text-transform: uppercase;
}

.product-page > .data > .name {
  font-size: 1.5em;
  font-weight: 500;
  text-transform: uppercase;
}

.product-page > .data > .description {
  height: 100%;
}

.product-page > .data > .conditions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.product-page > .data > .conditions > .title {
  font-size: 1rem;
  font-weight: 400;
}

.product-page > .data > .conditions > button {
  font-size: 1rem;
  font-weight: 400;
  background-color: var(--tertiary-color);
  outline: none;
  border: 1px solid var(--quinary-color);
  color: var(--quinary-color);
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}

.product-page > .data > .conditions > button.selected {
  background-color: var(--quinary-color);
  color: var(--tertiary-color);
}

.product-page > .data > .conditions > button.disabled {
  background-color: var(--tertiary-color);
  color: var(--quinary-color);
  cursor: not-allowed;
}

.product-page > .data > .sizes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.product-page > .data > .sizes > .title {
  font-size: 1rem;
  font-weight: 400;
}

.product-page > .data > .sizes > button {
  font-size: 1rem;
  font-weight: 400;
  background-color: var(--tertiary-color);
  outline: none;
  border: 1px solid var(--quinary-color);
  color: var(--quinary-color);
  border-radius: 5px;
  pointer: cursor;
  cursor: pointer;
  margin: 5px;
}

.product-page > .data > .sizes > button.selected {
  background-color: var(--quinary-color);
  color: var(--tertiary-color);
}

.product-page > .data > .sizes > button.disabled {
  background-color: var(--secondary-color);
  color: var(--quinary-color);
  cursor: not-allowed;
}

.product-page > .data > .choices {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.product-page > .data > .choices > .title {
  font-size: 1rem;
  font-weight: 400;
}

.product-page > .data > .choices > button {
  font-size: 1rem;
  font-weight: 400;
  background-color: var(--tertiary-color);
  outline: none;
  border: 1px solid var(--quinary-color);
  color: var(--quinary-color);
  border-radius: 5px;
  pointer: cursor;
  cursor: pointer;
  margin: 5px;
}

.product-page > .data > .choices > button.selected {
  background-color: var(--quinary-color);
  color: var(--tertiary-color);
}

.product-page > .data > .choices > button.disabled {
  background-color: var(--secondary-color);
  color: var(--quinary-color);
  cursor: not-allowed;
}

.product-page > .data > .price {
  font-size: 1.5em;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 10px;
}

.product-page > .data > .buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.product-page > .data > .buttons > button {
  font-size: 1.1rem;
  font-weight: 400;
  padding: 5px;
  background-color: var(--tertiary-color);
  outline: none;
  border: 1px solid var(--quinary-color);
  color: var(--quinary-color);
  border-radius: 5px;
  margin-right: 10px;
}

.product-page > .data > .buttons > button.disabled {
  background-color: var(--tertiary-color) !important;
  color: var(--quinary-color) !important;
  cursor: not-allowed !important;
}

.product-page > .data > .buttons > button {
  background-color: var(--quinary-color);
  color: var(--tertiary-color);
  cursor: pointer;
}

.price-details {
  margin-top: 5px;
}

.inventory-item-description {
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 5px;
}

@media screen and (max-width: 767px) {
  .product-page {
    flex-direction: column;
    height: auto;
    width: calc(100% - 20px);
    padding: 10px;
    border: unset;
    border-radius: unset;
  }

  .product-page > .images {
    width: 100%;
  }

  /* .product-page > .images > .current-image {
    height: 400px;
  } */

  .product-page > .images > .thumbnails > .images {
    flex-direction: row;
    align-items: center;
  }

  .product-page > .images > .thumbnails > .images > img {
    width: 30%;
    height: auto;
  }

  .product-page > .data {
    width: 100%;
    padding: 0;
  }

  .product-page > .data > .brand {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .product-page > .data > .name {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .product-page > .data > .price {
    margin-top: 5px;
  }

  .product-page > .data > .buttons {
    width: 100%;
    padding: 5px;
  }

  .product-page > .data > .buttons > button {
    margin-right: 5px;
    width: calc(50% - 5px);
  }
}
