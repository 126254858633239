.ant-picker-cell.ant-picker-cell-range-start.ant-picker-cell-in-view
  > .ant-picker-cell-inner {
  background: #7d6900 !important;
}

.ant-picker-cell.ant-picker-cell-in-range.ant-picker-cell-in-view
  > .ant-picker-cell-inner {
  color: #7d6900 !important;
}

.ant-picker-cell.ant-picker-cell-range-end.ant-picker-cell-in-view
  > .ant-picker-cell-inner {
  background: #7d6900 !important;
}

.filter-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}

.statistics {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}
