.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100dvh;
}

.store {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 90dvh;
}

.ant-image-preview-img {
  background-color: #ffffff;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
}

.main-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.loader-img-shining {
  height: 500px;
  width: 500px;
  object-fit: contain;
  animation: shining 2s infinite;
}

@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.no-results,
.not-found,
.request-submitted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
  color: var(--tertiary-color);
}

.no-results > img,
.not-found > img,
.request-submitted > img {
  height: 500px;
  width: 500px;
  object-fit: contain;
}

.no-results > h1,
.not-found > h1,
.request-submitted > h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.no-results > p,
.not-found > p,
.request-submitted > p {
  font-size: 1rem;
  font-weight: 400;
  margin-block-start: 1rem;
  margin-block-end: unset;
}

@media (max-width: 767px) {
  .loader-img-shining {
    width: 70vw;
    height: 70vw;
  }

  .store {
    width: 100%;
    margin-top: 91px;
    min-height: unset;
  }

  .breadcrumb {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
  }

  .container {
    overflow-y: hidden;
  }

  .no-results > img,
  .not-found > img,
  .request-submitted > img {
    height: unset;
    width: 70%;
  }
}

@media (min-width: 767px) {
  .store {
    width: calc((3 * 252px) + 2rem);
  }

  .breadcrumb {
    padding-bottom: 1rem;
    padding-left: 1rem;
  }
}

@media (min-width: 1024px) {
  .store {
    width: calc((5 * 252px) + 4rem);
  }
}
