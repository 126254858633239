.filter-container > .filter {
  margin-bottom: 1em;
}

.filter-container > .filter > .title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5em;
  color: #4b4b4b;
}

.filter-container > .buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.filter-container > .buttons Button {
  width: 49% !important;
}
