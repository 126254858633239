.orders-dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100vh - 40px);
  width: calc(100% - 40px);
  overflow-y: auto;
  padding: 20px;
}

.orders-dashboard h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: var(--quaternary-color);
}

.order-item > img {
  width: 50px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.orders-dashboard p {
  margin-block-start: unset;
  margin-block-end: unset;
}

.orders-dashboard .ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: var(--quinary-color) transparent;
  scroll-behavior: smooth;
}

.orders-dashboard .order-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.orders-dashboard .order-actions button {
  border: none;
  border-radius: 5px;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  font-size: 14px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px;
}

.tracking-number-input {
  border: 1px solid var(--quinary-color);
  outline: unset;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.sold-item > td {
  background-color: rgba(255, 0, 0, 0.25) !important;
}
