.product-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 250px;
  height: 100%;
  border: 1px solid var(--quinary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
  transition: all 0.3s;
  color: var(--tertiary-color);
}

.product-card .data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-top: 1px solid var(--primary-color);
  background-color: var(--quaternary-color);
}

.product-card > .data > .title {
  width: calc(100% - 20px);
  font-size: 1.2rem;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: uppercase;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}

.product-card > .data > .price {
  display: flex;
  justify-content: center;
  font-size: 1.45rem;
  font-weight: 300;
  padding-bottom: 5px;
}

.product-card > .data > .padding {
  padding: 0.5rem;
}

.product-card > .data > .padding > .data-data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.product-card > .data > .padding > .data-data.sizes {
  margin-bottom: 5px;
}

.product-card > .data > .padding > .data-title {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 5px;
}

.product-card > .data > .padding > .conditions {
  margin-bottom: 5px;
}

.product-card > .data > .padding > .data-data > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-right: 5px;
  margin-top: 5px;
  /* border: 1px solid var(--primary-color); */
  border: none;
  border-radius: 5px;
  background-color: var(--tertiary-color);
  color: var(--secondary-color);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}

.product-card > .data > .padding > .data-data > button.active {
  background-color: var(--secondary-color);
  color: var(--tertiary-color);
}

.product-card > .data > .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: unset;
  border-top: 1px solid var(--primary-color);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--tertiary-color);
  color: var(--quaternary-color);
  transition: all 0.3s;
}

.product-card > .data > .buttons button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--tertiary-color);
  font-size: 0.9rem;
  font-weight: 400;
  height: 100%;
  width: 50%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  color: var(--quaternary-color);
}

.product-card > .data > .buttons button:nth-child(1) {
  border-right: 1px solid var(--primary-color);
  border-bottom-right-radius: unset;
  background-color: var(--quinary-color);
  color: var(--tertiary-color);
}

.pre-owned {
  font-size: 0.8rem;
}

.ant-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  background-color: #ffffff;
}

.ant-image-img {
  /* object-fit: cover; */
  object-fit: contain;
  width: 100%;
}

@media (max-width: 767px) {
  .product-card {
    width: calc(50% - 0.4rem);
  }

  .product-card > .data > .buttons button {
    font-size: 0.8rem;
  }

  .product-card > .data > .title {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .product-card > .data > .price {
    font-size: 1.3rem;
    font-weight: 500;
    margin-block-end: 5px;
  }

  .product-card > .data > .padding > .data-data > button {
    height: 25px;
    font-size: 0.9rem;
  }

  .product-card > .data > .padding > .data-title {
    font-size: 0.9rem;
  }

  .pre-owned {
    font-size: 0.7rem !important;
    line-height: 0.7rem;
  }
}
