.navigation-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: calc(100% - 2rem);
  margin-bottom: 1rem;
  background-color: var(--secondary-color);
}

.navigation-bar > .full-width {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.navigation-bar > .full-width.reversed {
  justify-content: flex-start;
}

.navigation-bar > .full-width > .cart-button {
  color: #000;
  padding: 10px;
  width: 2rem;
  cursor: pointer;
}

.navigation-bar .logo {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 10px;
  margin-top: 1rem;
  cursor: pointer;
  width: 11rem;
}

.navigation-bar > .navigation-links,
.navigation-bar > .navigation-links > .links {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 10px 0;
}

.navigation-bar > .navigation-links .link {
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  color: var(--tertiary-color);
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.navigation-bar > .navigation-links .link.active {
  color: #000;
}

.navigation-bar .search-bar {
  width: 300px;
}

.navigation-bar
  > .navigation-links
  .navigation-item
  > .navigation-dropdown-items {
  display: none;
  position: absolute;
  box-shadow: 0 0 10px var(--primary-color);
  padding: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  margin-top: 25px;
  z-index: 100;
  background-color: var(--secondary-color);
  max-height: 200px;
  overflow-y: auto;
}

/* .navigation-bar .logo {
  -webkit-filter: drop-shadow(5px 5px 5px var(--primary-color));
  filter: drop-shadow(5px 5px 5px var(--primary-color));
} */

.navigation-bar
  > .navigation-links
  .navigation-item
  > .navigation-dropdown-items.active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.navigation-bar
  > .navigation-links
  .navigation-item
  > .navigation-dropdown-items
  .link {
  margin: unset !important;
}

.menu-btn {
  display: none;
}

.close-btn {
  display: none;
}

.navigation-item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  color: var(--quaternary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  height: 30px;
  background-color: var(--tertiary-color);
}

.filter-button > .title {
  margin-left: 5px;
  font-size: 0.8rem;
}

.filter-button > img {
  width: 1.5rem;
}

@media (max-width: 767px) {
  .navigation-bar {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-color);
    padding: unset;
    margin: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    transition: top 0.3s ease-in-out;
  }

  .close-btn {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    z-index: 1000;
    cursor: pointer;
  }

  .navigation-bar .logo {
    font-size: 1.2rem;
    width: 70px;
    margin-top: unset;
  }

  /*
    -webkit-filter: drop-shadow(2px 2px 2px var(--primary-color));
    filter: drop-shadow(2px 2px 2px var(--primary-color)); 
  */

  .navigation-bar > .full-width.search-bar-container {
    display: flex;
    width: unset;
    margin-top: unset;
  }

  .navigation-bar .search-bar {
    width: 100%;
  }

  .navigation-bar > .full-width {
    display: none;
  }

  .navigation-bar > .navigation-links,
  .navigation-bar > .navigation-links > .links {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--secondary-color);
    z-index: 100;
    overflow: hidden;
  }

  .navigation-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .navigation-bar > .navigation-links.active,
  .navigation-bar > .navigation-links.active > .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .navigation-bar > .navigation-links.active > .links {
    margin-top: 70px;
    width: 100%;
  }

  .navigation-bar > .navigation-links.active > .links .link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 2rem;
    color: var(--tertiary-color);
    padding: 0.5rem 0;
  }

  .navigation-bar
    > .navigation-links
    .navigation-item
    > .navigation-dropdown-items.active {
    position: relative;
    box-shadow: unset;
    border: unset;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .navigation-bar
    > .navigation-links
    .navigation-item
    > .navigation-dropdown-items
    .link {
    font-size: 1.7rem !important;
  }

  .menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    padding: 10px;
  }

  .filter-button > .title {
    display: none;
  }

  .filter-button > img {
    padding: 2px;
  }
}
