.request-approved {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  background-color: var(--tertiary-color);
  border-radius: 5px;
  border: 6px solid var(--primary-color);
  color: var(--quinary-color);
  width: calc(100% - 60px);
}

.request-approved > h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.request-approved > img {
  width: 40%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.request-approved > p {
  font-size: 1.3rem;
  font-weight: 300;
  margin-block-start: 20px;
  margin-block-end: 10px;
}

.request-purchase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  background-color: var(--tertiary-color);
  border-radius: 5px;
  border: 6px solid var(--primary-color);
  color: var(--quinary-color);
  width: calc(100% - 60px);
}
.request-purchase > h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  margin-block-start: unset;
  margin-block-end: unset;
}

.request-purchase > .items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.request-purchase > .items > .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 200px;
  width: 100%;
  padding: 10px;
  margin: 10px;
  /* border: 1px solid var(--primary-color); */
  /* border-radius: 5px; */
}

.request-purchase > .items > .item > img {
  height: 100%;
  width: auto;
  aspect-ratio: 1/1;
  border-radius: 5px;
  object-fit: contain;
  border: 1px solid var(--primary-color);
}

.request-purchase > .items > .item > .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  height: 100%;
  width: calc(100% - 260px);
}

.request-purchase > .items > .item > .info h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-block-start: unset;
  margin-block-end: 5px;
  text-transform: capitalize;
}

.request-purchase > .items > .item > .info > p {
  font-size: 1.3rem;
  font-weight: 300;
  margin-block-start: unset;
  margin-block-end: unset;
}

.request-purchase > .items > .item > .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 10px; */
  /* height: calc(100% - 20px); */
}

.request-purchase > .items > .item > .actions > button {
  padding: unset;
  margin: unset;
  border: unset;
  background-color: unset;
}

.request-purchase > .items > .item > .actions > button > img {
  height: 2rem;
  width: auto;
  cursor: pointer;
}

.request-purchase > .total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.request-purchase > .total > h2 {
  font-size: 1.3rem;
  font-weight: 400;
  margin-block-start: 5px;
  margin-block-end: unset;
}

.request-purchase > form.purchase-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.request-purchase > form.purchase-form > .form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  margin-top: 10px;
}

.request-purchase > form.purchase-form > .form-row > label {
  font-size: 1.3rem;
  font-weight: 300;
  width: 110px;
}

.request-purchase > form.purchase-form > .form-row > input {
  padding: 5px;
  font-size: 1.3rem;
  font-weight: 300;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  width: calc(100% - 150px);
}

.request-purchase > form.purchase-form > .form-row > input:focus {
  outline: none;
  border: 1px solid var(--secondary-color);
  background-color: var(--tertiary-color);
}

.request-purchase > form.purchase-form > button {
  padding: 10px;
  margin-top: 20px;
  border: none;
  background-color: var(--quinary-color);
  color: var(--tertiary-color);
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
}

.request-purchase > p {
  font-size: 1.3rem;
  font-weight: 300;
  margin-block-start: 20px;
  margin-block-end: 10px;
}

@media (max-width: 767px) {
  .request-approved {
    border: unset;
    box-shadow: unset;
    border-radius: unset;
    width: calc(100% - 60px);
  }

  .request-approved > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    margin-block-start: unset;
    margin-block-end: unset;
  }

  .request-approved > img {
    width: 60%;
    height: auto;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  .request-approved > p {
    font-size: 1.1rem;
    font-weight: 300;
    margin-block-start: 20px;
    margin-block-end: 10px;
  }

  .request-purchase {
    border: unset;
    box-shadow: unset;
    border-radius: unset;
    width: calc(100% - 60px);
  }

  .request-purchase > h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    margin-block-start: unset;
    margin-block-end: unset;
  }

  .request-purchase > .items > .item {
    height: 150px;
    width: 100%;
    padding: 10px;
    margin: 10px;
  }

  .request-purchase > .items > .item > .info {
    margin-left: 20px;
    height: 100%;
    width: calc(100% - 200px);
  }

  .request-purchase > .items > .item > .info h3 {
    font-size: 1.3rem;
    font-weight: 500;
    margin-block-start: unset;
    margin-block-end: 5px;
    text-transform: capitalize;
  }

  .request-purchase > .items > .item > .info > p {
    font-size: 1.1rem;
    font-weight: 300;
    margin-block-start: unset;
    margin-block-end: unset;
  }

  .request-purchase > .items > .item > .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 10px; */
    /* height: calc(100% - 20px); */
  }

  .request-purchase > .items > .item > .actions > button {
    padding: unset;
    margin: unset;
    border: unset;
    background-color: unset;
  }

  .request-purchase > .items > .item > .actions > button > img {
    height: 1.5rem;
    width: auto;
    cursor: pointer;
  }

  .request-purchase > .total > h2 {
    font-size: 1.1rem;
    font-weight: 400;
    margin-block-start: 5px;
    margin-block-end: unset;
  }

  .request-purchase > form.purchase-form > .form-row {
    width: 100%;
  }
}
