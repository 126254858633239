.list-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.list-menu > .list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding-top: 0.1vw;
  padding-bottom: 0.1vw;
  text-decoration: none;
  overflow-y: auto;
  border-bottom: 2px solid #4b4b4b;
}

.list-menu > .list-item.active {
  /* grey background */
  background-color: #dadada;
}

.list-menu > .list-item > .icon {
  padding: 0.5vw;
  width: 1.5vw;
}

.list-menu > .list-item > .name {
  color: var(--tertiary-color);
  font-size: 1.1vw;
}

.list-menu > .list-item.active > .name {
  color: var(--quaternary-color);
}

@media (max-width: 767px) {
  .list-menu {
    display: none;
  }
}
