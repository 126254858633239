.form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.form-container > .form {
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: auto;
  background-color: var(--secondary-color);
  z-index: 2;
  border-radius: 10px;
  box-shadow: 0 0 10px 5px rgba(125, 104, 0, 0.32);
  pointer-events: auto;
}

.form-container > .form > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  padding: 1%;
  background-color: #4b4b4b;
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.form-container > .form > .header > .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 3rem;
  font-size: 1.5em;
}

.form-container > .form > .header > .buttons img {
  width: 100%;
  cursor: pointer;
}

.form-container > .form > .header > .buttons:nth-of-type(2) {
  justify-content: flex-end;
}

.form-container > .form > .elements > .form-elements {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 96%;
  height: 100%;
  max-height: calc(100vh / 1.5);
  overflow-y: auto;
  padding: 2%;
}

.form-container > .form > .elements > .submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: 10%;
  padding: 1%;
  background-color: #4b4b4b;
  color: #fff;
  font-size: 1vw;
  font-weight: bold;
  border-radius: 0 0 10px 10px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: 0.3s;
}

.form-container > .form > .elements > .submit button {
  width: 100%;
  height: 100%;
  background-color: #4b4b4b;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container > .form > .pop-up {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  /* height: 25%; */
  width: 25%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 0 0 10px 5px rgba(125, 104, 0, 0.32);
}

.form-container > .form > .pop-up > .pop-up-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  height: 20%;
  padding: 0 1% 0 1%;
  background-color: #4b4b4b;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.form-container > .form > .pop-up > .pop-up-header > .button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 1.1em;
  font-size: 1.5em;
  cursor: pointer;
}

.form-container > .form > .pop-up > .pop-up-body {
  padding: 2%;
  font-size: 1em;
  color: #000;
  text-shadow: none;
}

.form-container > .form > .pop-up > .pop-up-body p {
  margin: 0;
  padding: 0;
}

.form-container > .form > .pop-up > .buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 98%;
  height: 20%;
  padding: 1%;
  background-color: #4b4b4b;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  border-radius: 0 0 10px 10px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.form-container > .form > .pop-up > .buttons > button {
  width: 100%;
  height: 100%;
  background-color: #4b4b4b;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.form-container > .form > .pop-up > .buttons > button.cancel {
  border-right: 1px solid #fff;
}

.form-container .switches {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .form-container > .form {
    height: 100%;
    width: 100%;
  }

  .form-container > .form > .header {
    border-radius: unset;
    padding: 2%;
    height: 3rem;
  }

  .form-container > .form > .header > .buttons {
    width: 8%;
  }

  .form-container > .form > .elements > .submit {
    height: 3.5rem;
    border-radius: unset;
  }

  .form-container > .form > .elements > .submit button {
    font-size: 1.2rem;
  }

  .form-container > .form > .elements {
    height: calc(100% - 3.5rem);
  }

  .form-container > .form > .elements > .form-elements {
    height: calc(100% - 4% - 3.5rem);
    max-height: unset;
    overflow-y: auto;
  }

  .form-container > .form > .pop-up {
    width: 90%;
  }

  .form-container > .form > .pop-up > .pop-up-header {
    padding: 1% 2% 1% 2%;
    width: 96%;
  }
}
