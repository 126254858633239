.admin-panel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100dvh;
  width: 100vw;
}

.admin-panel > .side-menu {
  border-right: 2px solid #4b4b4b;
  height: 100dvh;
  width: 15vw;
}

.log-out-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: var(--tertiary-color);
  font-size: 1.1rem;
  border: none;
  cursor: pointer;
  width: fit-content;
}

.log-out-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .admin-panel {
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
  }

  .admin-panel > .side-menu {
    border-right: none;
    height: unset;
    width: 100%;
  }

  .log-out-btn {
    display: none;
  }
}
