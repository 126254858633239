.content-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  background-color: var(--tertiary-color);
  border-radius: 5px;
  border: 6px solid var(--quinary-color);
  color: var(--quaternary-color);
  width: calc(100% - 60px);
}

.content-page-container.about-us {
  flex-direction: row !important;
  justify-content: space-between !important;
}

.content-page-container p {
  text-align: left;
  font-size: 1.3rem;
  font-weight: 300;
  margin-block-start: unset;
}

.content-page-container h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  margin-block-start: unset;
  color: var(--quinary-color);
}

.content-page-container h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  margin-block-start: unset;
  color: var(--quinary-color);
}

.ybs-video {
  height: 500px;
  border-radius: 5px;
  border: 6px solid var(--quinary-color);
  margin-left: 20px;
}

@media (max-width: 767px) {
  .content-page-container {
    border: unset;
    box-shadow: unset;
    border-radius: unset;
    flex-direction: column;
    align-items: flex-start;
  }

  .content-page-container.about-us {
    flex-direction: column !important;
    justify-content: center !important;
  }

  .ybs-video {
    width: 100%;
    height: unset;
    margin-left: unset;
  }
}
