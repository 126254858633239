.cart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}

.cart > .cart-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--quinary-color) transparent;
  scroll-behavior: smooth;
}

.cart > .cart-items > .cart-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(95% - 2rem);
  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid var(--quinary-color);
  border-radius: 5px;
}

.cart > .cart-items > .cart-item > img {
  width: 100px;
  height: 100px;
  background-color: #ffffffff;
  border-radius: 5px;
  /* object-fit: cover; */
  object-fit: contain;
  border: 1px solid var(--primary-color);
  aspect-ratio: 1/1;
}

.cart > .cart-items > .cart-item > .cart-item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--quaternary-color);
  margin-left: 1rem;
  width: calc(100% - 100px - 3rem);
  overflow-x: hidden;
  font-size: 1rem;
}

.cart > .cart-items > .cart-item > .cart-item-details > h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  text-transform: capitalize;
  cursor: pointer;
}

.cart > .cart-items > .cart-item > .cart-item-details > p {
  font-size: 1rem;
  font-weight: 400;
  margin-block-start: 0.2rem;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
}

.cart > .cart-items > .cart-item > .cart-item-details > .price {
  font-size: 1.2rem;
  font-weight: 300;
  margin-block-start: 0.5rem;
  margin-inline-start: unset;
  margin-inline-end: unset;
}

.cart > .cart-items > .cart-item > .actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.cart > .cart-items > .cart-item > .actions > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  background-color: unset;
  border: none;
  margin-left: 0.5rem;
  padding: unset;
  cursor: pointer;
}

.cart > .cart-items > .cart-item > .actions > button > img {
  width: 100%;
}

.cart > .cart-items-total {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
  color: var(--quaternary-color);
  margin: 1rem 0;
}

.cart > .cart-items-total > h3 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  text-transform: capitalize;
}

.cart > .actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 100%;
}

.cart > .actions > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: var(--quinary-color);
  color: var(--tertiary-color);
  border: none;
  border-radius: 5px;
  margin: 1rem 0.5rem;
  padding: 0 1rem;
  cursor: pointer;
  font-size: 1rem;
}

.cart > .empty-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: var(--quaternary-color);
  font-size: 1.2rem;
}
