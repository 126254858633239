.dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100dvh - 2vw);
  width: calc(85vw - 2vw);
  padding: 1vw;
}

.dashboard-lists {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 6vw);
  padding: 0;
  margin: 0;
}

.dashboard > .mobile {
  display: none;
}

.dashboard > .dashboard-menu {
  display: none;
}

.dashboard > .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--tertiary-color);
  font-size: 1.5rem;
  width: 100%;
}

.dashboard > .header > .back {
  display: none;
}

@media (max-width: 767px) {
  .dashboard > .mobile {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .dashboard > .dashboard-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: unset;
    margin: 5px;
    border-radius: 5px;
    width: calc(100% - 10px);
  }

  .dashboard > .dashboard-menu.hide {
    display: none;
  }

  .dashboard > .dashboard-menu > .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    background-color: var(--tertiary-color);
    color: #4b4b4b;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
  }

  .dashboard > .dashboard-menu > .list-item > .icon {
    height: 2rem;
    width: 2rem;
    margin: 0.5rem;
  }

  .dashboard {
    height: 100%;
    width: calc(100% - 2vw);
    overflow-y: hidden;
  }

  .dashboard-lists {
    display: none;
    /* width: calc(100vw - 20px);
    height: calc(100dvh - 132px);
    padding: 10px; */
  }

  .dashboard > .header {
    font-size: 1.2rem;
    height: 50px;
  }

  .dashboard > .header > .back {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #4b4b4b;
    cursor: pointer;
  }

  .dashboard > .header > .back.hide {
    display: none;
  }

  .dashboard > .header > .back img {
    height: 50px;
    width: 50px;
  }
}
