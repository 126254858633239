.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--tertiary-color);
  color: var(--quinary-color);
  width: calc(100% - 2rem);
  position: relative;
  padding: 1rem;
  margin-top: 2rem;
}

.footer .credits {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
}

.footer a {
  color: var(--quinary-color);
  text-decoration: none;
}

.footer-sections {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.footer-section .contact-item {
  display: flex;
  flex-direction: row;
  line-height: 1.5rem;
}

.footer-section .contact-item .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  width: 1rem;
}

.footer .title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footer .link {
  color: var(--quinary-color);
  text-decoration: none;
  margin-right: 1rem;
}

.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
}

.footer-section > .link {
  margin-bottom: 0.5rem;
  border: unset;
  background: unset;
  cursor: pointer;
  font-size: 1rem;
}

@media (min-width: 767px) {
  .footer-sections {
    width: 70vw;
  }
}

@media (max-width: 767px) {
  .footer {
    margin-top: unset;
  }
  .footer-sections {
    width: 100%;
    flex-direction: column;
  }
  .footer .title {
    font-size: 1rem;
  }

  .footer-section {
    padding: 0.5rem;
    width: 100%;
  }
}
